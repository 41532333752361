export const TIMEZONES = [
  {
    name: 'Africa/Abidjan',
    offset: '0',
    title: 'GMT (+00:00) Abidjan',
    id: 'AFRICA/ABIDJAN'
  },
  {
    name: 'Africa/Accra',
    offset: '0',
    title: 'GMT (+00:00) Accra',
    id: 'AFRICA/ACCRA'
  },
  {
    name: 'Africa/Addis_Ababa',
    offset: '10800',
    title: 'GMT (+03:00) Addis Ababa',
    id: 'AFRICA/ADDIS_ABABA'
  },
  {
    name: 'Africa/Algiers',
    offset: '3600',
    title: 'GMT (+01:00) Algiers',
    id: 'AFRICA/ALGIERS'
  },
  {
    name: 'Africa/Asmara',
    offset: '10800',
    title: 'GMT (+03:00) Asmara',
    id: 'AFRICA/ASMARA'
  },
  {
    name: 'Africa/Bamako',
    offset: '0',
    title: 'GMT (+00:00) Bamako',
    id: 'AFRICA/BAMAKO'
  },
  {
    name: 'Africa/Bangui',
    offset: '3600',
    title: 'GMT (+01:00) Bangui',
    id: 'AFRICA/BANGUI'
  },
  {
    name: 'Africa/Banjul',
    offset: '0',
    title: 'GMT (+00:00) Banjul',
    id: 'AFRICA/BANJUL'
  },
  {
    name: 'Africa/Bissau',
    offset: '0',
    title: 'GMT (+00:00) Bissau',
    id: 'AFRICA/BISSAU'
  },
  {
    name: 'Africa/Blantyre',
    offset: '7200',
    title: 'GMT (+02:00) Blantyre',
    id: 'AFRICA/BLANTYRE'
  },
  {
    name: 'Africa/Brazzaville',
    offset: '3600',
    title: 'GMT (+01:00) Brazzaville',
    id: 'AFRICA/BRAZZAVILLE'
  },
  {
    name: 'Africa/Bujumbura',
    offset: '7200',
    title: 'GMT (+02:00) Bujumbura',
    id: 'AFRICA/BUJUMBURA'
  },
  {
    name: 'Africa/Cairo',
    offset: '7200',
    title: 'GMT (+02:00) Cairo',
    id: 'AFRICA/CAIRO'
  },
  {
    name: 'Africa/Casablanca',
    offset: '3600',
    title: 'GMT (+01:00) Casablanca',
    id: 'AFRICA/CASABLANCA'
  },
  {
    name: 'Africa/Ceuta',
    offset: '7200',
    title: 'GMT (+02:00) Ceuta',
    id: 'AFRICA/CEUTA'
  },
  {
    name: 'Africa/Conakry',
    offset: '0',
    title: 'GMT (+00:00) Conakry',
    id: 'AFRICA/CONAKRY'
  },
  {
    name: 'Africa/Dakar',
    offset: '0',
    title: 'GMT (+00:00) Dakar',
    id: 'AFRICA/DAKAR'
  },
  {
    name: 'Africa/Dar_es_Salaam',
    offset: '10800',
    title: 'GMT (+03:00) Dar es Salaam',
    id: 'AFRICA/DAR_ES_SALAAM'
  },
  {
    name: 'Africa/Djibouti',
    offset: '10800',
    title: 'GMT (+03:00) Djibouti',
    id: 'AFRICA/DJIBOUTI'
  },
  {
    name: 'Africa/Douala',
    offset: '3600',
    title: 'GMT (+01:00) Douala',
    id: 'AFRICA/DOUALA'
  },
  {
    name: 'Africa/El_Aaiun',
    offset: '3600',
    title: 'GMT (+01:00) El Aaiun',
    id: 'AFRICA/EL_AAIUN'
  },
  {
    name: 'Africa/Freetown',
    offset: '0',
    title: 'GMT (+00:00) Freetown',
    id: 'AFRICA/FREETOWN'
  },
  {
    name: 'Africa/Gaborone',
    offset: '7200',
    title: 'GMT (+02:00) Gaborone',
    id: 'AFRICA/GABORONE'
  },
  {
    name: 'Africa/Harare',
    offset: '7200',
    title: 'GMT (+02:00) Harare',
    id: 'AFRICA/HARARE'
  },
  {
    name: 'Africa/Johannesburg',
    offset: '7200',
    title: 'GMT (+02:00) Johannesburg',
    id: 'AFRICA/JOHANNESBURG'
  },
  {
    name: 'Africa/Juba',
    offset: '10800',
    title: 'GMT (+03:00) Juba',
    id: 'AFRICA/JUBA'
  },
  {
    name: 'Africa/Kampala',
    offset: '10800',
    title: 'GMT (+03:00) Kampala',
    id: 'AFRICA/KAMPALA'
  },
  {
    name: 'Africa/Khartoum',
    offset: '7200',
    title: 'GMT (+02:00) Khartoum',
    id: 'AFRICA/KHARTOUM'
  },
  {
    name: 'Africa/Kigali',
    offset: '7200',
    title: 'GMT (+02:00) Kigali',
    id: 'AFRICA/KIGALI'
  },
  {
    name: 'Africa/Kinshasa',
    offset: '3600',
    title: 'GMT (+01:00) Kinshasa',
    id: 'AFRICA/KINSHASA'
  },
  {
    name: 'Africa/Lagos',
    offset: '3600',
    title: 'GMT (+01:00) Lagos',
    id: 'AFRICA/LAGOS'
  },
  {
    name: 'Africa/Libreville',
    offset: '3600',
    title: 'GMT (+01:00) Libreville',
    id: 'AFRICA/LIBREVILLE'
  },
  {
    name: 'Africa/Lome',
    offset: '0',
    title: 'GMT (+00:00) Lome',
    id: 'AFRICA/LOME'
  },
  {
    name: 'Africa/Luanda',
    offset: '3600',
    title: 'GMT (+01:00) Luanda',
    id: 'AFRICA/LUANDA'
  },
  {
    name: 'Africa/Lubumbashi',
    offset: '7200',
    title: 'GMT (+02:00) Lubumbashi',
    id: 'AFRICA/LUBUMBASHI'
  },
  {
    name: 'Africa/Lusaka',
    offset: '7200',
    title: 'GMT (+02:00) Lusaka',
    id: 'AFRICA/LUSAKA'
  },
  {
    name: 'Africa/Malabo',
    offset: '3600',
    title: 'GMT (+01:00) Malabo',
    id: 'AFRICA/MALABO'
  },
  {
    name: 'Africa/Maputo',
    offset: '7200',
    title: 'GMT (+02:00) Maputo',
    id: 'AFRICA/MAPUTO'
  },
  {
    name: 'Africa/Maseru',
    offset: '7200',
    title: 'GMT (+02:00) Maseru',
    id: 'AFRICA/MASERU'
  },
  {
    name: 'Africa/Mbabane',
    offset: '7200',
    title: 'GMT (+02:00) Mbabane',
    id: 'AFRICA/MBABANE'
  },
  {
    name: 'Africa/Mogadishu',
    offset: '10800',
    title: 'GMT (+03:00) Mogadishu',
    id: 'AFRICA/MOGADISHU'
  },
  {
    name: 'Africa/Monrovia',
    offset: '0',
    title: 'GMT (+00:00) Monrovia',
    id: 'AFRICA/MONROVIA'
  },
  {
    name: 'Africa/Nairobi',
    offset: '10800',
    title: 'GMT (+03:00) Nairobi',
    id: 'AFRICA/NAIROBI'
  },
  {
    name: 'Africa/Ndjamena',
    offset: '3600',
    title: 'GMT (+01:00) Ndjamena',
    id: 'AFRICA/NDJAMENA'
  },
  {
    name: 'Africa/Niamey',
    offset: '3600',
    title: 'GMT (+01:00) Niamey',
    id: 'AFRICA/NIAMEY'
  },
  {
    name: 'Africa/Nouakchott',
    offset: '0',
    title: 'GMT (+00:00) Nouakchott',
    id: 'AFRICA/NOUAKCHOTT'
  },
  {
    name: 'Africa/Ouagadougou',
    offset: '0',
    title: 'GMT (+00:00) Ouagadougou',
    id: 'AFRICA/OUAGADOUGOU'
  },
  {
    name: 'Africa/Porto-Novo',
    offset: '3600',
    title: 'GMT (+01:00) Porto-Novo',
    id: 'AFRICA/PORTO-NOVO'
  },
  {
    name: 'Africa/Sao_Tome',
    offset: '0',
    title: 'GMT (+00:00) Sao Tome',
    id: 'AFRICA/SAO_TOME'
  },
  {
    name: 'Africa/Tripoli',
    offset: '7200',
    title: 'GMT (+02:00) Tripoli',
    id: 'AFRICA/TRIPOLI'
  },
  {
    name: 'Africa/Tunis',
    offset: '3600',
    title: 'GMT (+01:00) Tunis',
    id: 'AFRICA/TUNIS'
  },
  {
    name: 'Africa/Windhoek',
    offset: '7200',
    title: 'GMT (+02:00) Windhoek',
    id: 'AFRICA/WINDHOEK'
  },
  {
    name: 'America/Adak',
    offset: '-32400',
    title: 'GMT (-09:00) Adak',
    id: 'AMERICA/ADAK'
  },
  {
    name: 'America/Anchorage',
    offset: '-28800',
    title: 'GMT (-08:00) Anchorage',
    id: 'AMERICA/ANCHORAGE'
  },
  {
    name: 'America/Anguilla',
    offset: '-14400',
    title: 'GMT (-04:00) Anguilla',
    id: 'AMERICA/ANGUILLA'
  },
  {
    name: 'America/Antigua',
    offset: '-14400',
    title: 'GMT (-04:00) Antigua',
    id: 'AMERICA/ANTIGUA'
  },
  {
    name: 'America/Araguaina',
    offset: '-10800',
    title: 'GMT (-03:00) Araguaina',
    id: 'AMERICA/ARAGUAINA'
  },
  {
    name: 'America/Argentina/Buenos_Aires',
    offset: '-10800',
    title: 'GMT (-03:00) Buenos Aires',
    id: 'AMERICA/ARGENTINA/BUENOS_AIRES'
  },
  {
    name: 'America/Argentina/Catamarca',
    offset: '-10800',
    title: 'GMT (-03:00) Catamarca',
    id: 'AMERICA/ARGENTINA/CATAMARCA'
  },
  {
    name: 'America/Argentina/Cordoba',
    offset: '-10800',
    title: 'GMT (-03:00) Cordoba',
    id: 'AMERICA/ARGENTINA/CORDOBA'
  },
  {
    name: 'America/Argentina/Jujuy',
    offset: '-10800',
    title: 'GMT (-03:00) Jujuy',
    id: 'AMERICA/ARGENTINA/JUJUY'
  },
  {
    name: 'America/Argentina/La_Rioja',
    offset: '-10800',
    title: 'GMT (-03:00) La Rioja',
    id: 'AMERICA/ARGENTINA/LA_RIOJA'
  },
  {
    name: 'America/Argentina/Mendoza',
    offset: '-10800',
    title: 'GMT (-03:00) Mendoza',
    id: 'AMERICA/ARGENTINA/MENDOZA'
  },
  {
    name: 'America/Argentina/Rio_Gallegos',
    offset: '-10800',
    title: 'GMT (-03:00) Rio Gallegos',
    id: 'AMERICA/ARGENTINA/RIO_GALLEGOS'
  },
  {
    name: 'America/Argentina/Salta',
    offset: '-10800',
    title: 'GMT (-03:00) Salta',
    id: 'AMERICA/ARGENTINA/SALTA'
  },
  {
    name: 'America/Argentina/San_Juan',
    offset: '-10800',
    title: 'GMT (-03:00) San Juan',
    id: 'AMERICA/ARGENTINA/SAN_JUAN'
  },
  {
    name: 'America/Argentina/San_Luis',
    offset: '-10800',
    title: 'GMT (-03:00) San Luis',
    id: 'AMERICA/ARGENTINA/SAN_LUIS'
  },
  {
    name: 'America/Argentina/Tucuman',
    offset: '-10800',
    title: 'GMT (-03:00) Tucuman',
    id: 'AMERICA/ARGENTINA/TUCUMAN'
  },
  {
    name: 'America/Argentina/Ushuaia',
    offset: '-10800',
    title: 'GMT (-03:00) Ushuaia',
    id: 'AMERICA/ARGENTINA/USHUAIA'
  },
  {
    name: 'America/Aruba',
    offset: '-14400',
    title: 'GMT (-04:00) Aruba',
    id: 'AMERICA/ARUBA'
  },
  {
    name: 'America/Asuncion',
    offset: '-14400',
    title: 'GMT (-04:00) Asuncion',
    id: 'AMERICA/ASUNCION'
  },
  {
    name: 'America/Atikokan',
    offset: '-18000',
    title: 'GMT (-05:00) Atikokan',
    id: 'AMERICA/ATIKOKAN'
  },
  {
    name: 'America/Bahia',
    offset: '-10800',
    title: 'GMT (-03:00) Bahia',
    id: 'AMERICA/BAHIA'
  },
  {
    name: 'America/Bahia_Banderas',
    offset: '-18000',
    title: 'GMT (-05:00) Bahia Banderas',
    id: 'AMERICA/BAHIA_BANDERAS'
  },
  {
    name: 'America/Barbados',
    offset: '-14400',
    title: 'GMT (-04:00) Barbados',
    id: 'AMERICA/BARBADOS'
  },
  {
    name: 'America/Belem',
    offset: '-10800',
    title: 'GMT (-03:00) Belem',
    id: 'AMERICA/BELEM'
  },
  {
    name: 'America/Belize',
    offset: '-21600',
    title: 'GMT (-06:00) Belize',
    id: 'AMERICA/BELIZE'
  },
  {
    name: 'America/Blanc-Sablon',
    offset: '-14400',
    title: 'GMT (-04:00) Blanc-Sablon',
    id: 'AMERICA/BLANC-SABLON'
  },
  {
    name: 'America/Boa_Vista',
    offset: '-14400',
    title: 'GMT (-04:00) Boa Vista',
    id: 'AMERICA/BOA_VISTA'
  },
  {
    name: 'America/Bogota',
    offset: '-18000',
    title: 'GMT (-05:00) Bogota',
    id: 'AMERICA/BOGOTA'
  },
  {
    name: 'America/Boise',
    offset: '-21600',
    title: 'GMT (-06:00) Boise',
    id: 'AMERICA/BOISE'
  },
  {
    name: 'America/Cambridge_Bay',
    offset: '-21600',
    title: 'GMT (-06:00) Cambridge Bay',
    id: 'AMERICA/CAMBRIDGE_BAY'
  },
  {
    name: 'America/Campo_Grande',
    offset: '-14400',
    title: 'GMT (-04:00) Campo Grande',
    id: 'AMERICA/CAMPO_GRANDE'
  },
  {
    name: 'America/Cancun',
    offset: '-18000',
    title: 'GMT (-05:00) Cancun',
    id: 'AMERICA/CANCUN'
  },
  {
    name: 'America/Caracas',
    offset: '-14400',
    title: 'GMT (-04:00) Caracas',
    id: 'AMERICA/CARACAS'
  },
  {
    name: 'America/Cayenne',
    offset: '-10800',
    title: 'GMT (-03:00) Cayenne',
    id: 'AMERICA/CAYENNE'
  },
  {
    name: 'America/Cayman',
    offset: '-18000',
    title: 'GMT (-05:00) Cayman',
    id: 'AMERICA/CAYMAN'
  },
  {
    name: 'America/Chicago',
    offset: '-18000',
    title: 'GMT (-05:00) Chicago',
    id: 'AMERICA/CHICAGO'
  },
  {
    name: 'America/Chihuahua',
    offset: '-21600',
    title: 'GMT (-06:00) Chihuahua',
    id: 'AMERICA/CHIHUAHUA'
  },
  {
    name: 'America/Costa_Rica',
    offset: '-21600',
    title: 'GMT (-06:00) Costa Rica',
    id: 'AMERICA/COSTA_RICA'
  },
  {
    name: 'America/Creston',
    offset: '-25200',
    title: 'GMT (-07:00) Creston',
    id: 'AMERICA/CRESTON'
  },
  {
    name: 'America/Cuiaba',
    offset: '-14400',
    title: 'GMT (-04:00) Cuiaba',
    id: 'AMERICA/CUIABA'
  },
  {
    name: 'America/Curacao',
    offset: '-14400',
    title: 'GMT (-04:00) Curacao',
    id: 'AMERICA/CURACAO'
  },
  {
    name: 'America/Danmarkshavn',
    offset: '0',
    title: 'GMT (+00:00) Danmarkshavn',
    id: 'AMERICA/DANMARKSHAVN'
  },
  {
    name: 'America/Dawson',
    offset: '-25200',
    title: 'GMT (-07:00) Dawson',
    id: 'AMERICA/DAWSON'
  },
  {
    name: 'America/Dawson_Creek',
    offset: '-25200',
    title: 'GMT (-07:00) Dawson Creek',
    id: 'AMERICA/DAWSON_CREEK'
  },
  {
    name: 'America/Denver',
    offset: '-21600',
    title: 'GMT (-06:00) Denver',
    id: 'AMERICA/DENVER'
  },
  {
    name: 'America/Detroit',
    offset: '-14400',
    title: 'GMT (-04:00) Detroit',
    id: 'AMERICA/DETROIT'
  },
  {
    name: 'America/Dominica',
    offset: '-14400',
    title: 'GMT (-04:00) Dominica',
    id: 'AMERICA/DOMINICA'
  },
  {
    name: 'America/Edmonton',
    offset: '-21600',
    title: 'GMT (-06:00) Edmonton',
    id: 'AMERICA/EDMONTON'
  },
  {
    name: 'America/Eirunepe',
    offset: '-18000',
    title: 'GMT (-05:00) Eirunepe',
    id: 'AMERICA/EIRUNEPE'
  },
  {
    name: 'America/El_Salvador',
    offset: '-21600',
    title: 'GMT (-06:00) El Salvador',
    id: 'AMERICA/EL_SALVADOR'
  },
  {
    name: 'America/Fort_Nelson',
    offset: '-25200',
    title: 'GMT (-07:00) Fort Nelson',
    id: 'AMERICA/FORT_NELSON'
  },
  {
    name: 'America/Fortaleza',
    offset: '-10800',
    title: 'GMT (-03:00) Fortaleza',
    id: 'AMERICA/FORTALEZA'
  },
  {
    name: 'America/Glace_Bay',
    offset: '-10800',
    title: 'GMT (-03:00) Glace Bay',
    id: 'AMERICA/GLACE_BAY'
  },
  {
    name: 'America/Goose_Bay',
    offset: '-10800',
    title: 'GMT (-03:00) Goose Bay',
    id: 'AMERICA/GOOSE_BAY'
  },
  {
    name: 'America/Grand_Turk',
    offset: '-14400',
    title: 'GMT (-04:00) Grand Turk',
    id: 'AMERICA/GRAND_TURK'
  },
  {
    name: 'America/Grenada',
    offset: '-14400',
    title: 'GMT (-04:00) Grenada',
    id: 'AMERICA/GRENADA'
  },
  {
    name: 'America/Guadeloupe',
    offset: '-14400',
    title: 'GMT (-04:00) Guadeloupe',
    id: 'AMERICA/GUADELOUPE'
  },
  {
    name: 'America/Guatemala',
    offset: '-21600',
    title: 'GMT (-06:00) Guatemala',
    id: 'AMERICA/GUATEMALA'
  },
  {
    name: 'America/Guayaquil',
    offset: '-18000',
    title: 'GMT (-05:00) Guayaquil',
    id: 'AMERICA/GUAYAQUIL'
  },
  {
    name: 'America/Guyana',
    offset: '-14400',
    title: 'GMT (-04:00) Guyana',
    id: 'AMERICA/GUYANA'
  },
  {
    name: 'America/Halifax',
    offset: '-10800',
    title: 'GMT (-03:00) Halifax',
    id: 'AMERICA/HALIFAX'
  },
  {
    name: 'America/Havana',
    offset: '-14400',
    title: 'GMT (-04:00) Havana',
    id: 'AMERICA/HAVANA'
  },
  {
    name: 'America/Hermosillo',
    offset: '-25200',
    title: 'GMT (-07:00) Hermosillo',
    id: 'AMERICA/HERMOSILLO'
  },
  {
    name: 'America/Indiana/Indianapolis',
    offset: '-14400',
    title: 'GMT (-04:00) Indianapolis',
    id: 'AMERICA/INDIANA/INDIANAPOLIS'
  },
  {
    name: 'America/Indiana/Knox',
    offset: '-18000',
    title: 'GMT (-05:00) Knox',
    id: 'AMERICA/INDIANA/KNOX'
  },
  {
    name: 'America/Indiana/Marengo',
    offset: '-14400',
    title: 'GMT (-04:00) Marengo',
    id: 'AMERICA/INDIANA/MARENGO'
  },
  {
    name: 'America/Indiana/Petersburg',
    offset: '-14400',
    title: 'GMT (-04:00) Petersburg',
    id: 'AMERICA/INDIANA/PETERSBURG'
  },
  {
    name: 'America/Indiana/Tell_City',
    offset: '-18000',
    title: 'GMT (-05:00) Tell City',
    id: 'AMERICA/INDIANA/TELL_CITY'
  },
  {
    name: 'America/Indiana/Vevay',
    offset: '-14400',
    title: 'GMT (-04:00) Vevay',
    id: 'AMERICA/INDIANA/VEVAY'
  },
  {
    name: 'America/Indiana/Vincennes',
    offset: '-14400',
    title: 'GMT (-04:00) Vincennes',
    id: 'AMERICA/INDIANA/VINCENNES'
  },
  {
    name: 'America/Indiana/Winamac',
    offset: '-14400',
    title: 'GMT (-04:00) Winamac',
    id: 'AMERICA/INDIANA/WINAMAC'
  },
  {
    name: 'America/Inuvik',
    offset: '-21600',
    title: 'GMT (-06:00) Inuvik',
    id: 'AMERICA/INUVIK'
  },
  {
    name: 'America/Iqaluit',
    offset: '-14400',
    title: 'GMT (-04:00) Iqaluit',
    id: 'AMERICA/IQALUIT'
  },
  {
    name: 'America/Jamaica',
    offset: '-18000',
    title: 'GMT (-05:00) Jamaica',
    id: 'AMERICA/JAMAICA'
  },
  {
    name: 'America/Juneau',
    offset: '-28800',
    title: 'GMT (-08:00) Juneau',
    id: 'AMERICA/JUNEAU'
  },
  {
    name: 'America/Kentucky/Louisville',
    offset: '-14400',
    title: 'GMT (-04:00) Louisville',
    id: 'AMERICA/KENTUCKY/LOUISVILLE'
  },
  {
    name: 'America/Kentucky/Monticello',
    offset: '-14400',
    title: 'GMT (-04:00) Monticello',
    id: 'AMERICA/KENTUCKY/MONTICELLO'
  },
  {
    name: 'America/Kralendijk',
    offset: '-14400',
    title: 'GMT (-04:00) Kralendijk',
    id: 'AMERICA/KRALENDIJK'
  },
  {
    name: 'America/La_Paz',
    offset: '-14400',
    title: 'GMT (-04:00) La Paz',
    id: 'AMERICA/LA_PAZ'
  },
  {
    name: 'America/Lima',
    offset: '-18000',
    title: 'GMT (-05:00) Lima',
    id: 'AMERICA/LIMA'
  },
  {
    name: 'America/Los_Angeles',
    offset: '-25200',
    title: 'GMT (-07:00) Los Angeles',
    id: 'AMERICA/LOS_ANGELES'
  },
  {
    name: 'America/Lower_Princes',
    offset: '-14400',
    title: 'GMT (-04:00) Lower Princes',
    id: 'AMERICA/LOWER_PRINCES'
  },
  {
    name: 'America/Maceio',
    offset: '-10800',
    title: 'GMT (-03:00) Maceio',
    id: 'AMERICA/MACEIO'
  },
  {
    name: 'America/Managua',
    offset: '-21600',
    title: 'GMT (-06:00) Managua',
    id: 'AMERICA/MANAGUA'
  },
  {
    name: 'America/Manaus',
    offset: '-14400',
    title: 'GMT (-04:00) Manaus',
    id: 'AMERICA/MANAUS'
  },
  {
    name: 'America/Marigot',
    offset: '-14400',
    title: 'GMT (-04:00) Marigot',
    id: 'AMERICA/MARIGOT'
  },
  {
    name: 'America/Martinique',
    offset: '-14400',
    title: 'GMT (-04:00) Martinique',
    id: 'AMERICA/MARTINIQUE'
  },
  {
    name: 'America/Matamoros',
    offset: '-18000',
    title: 'GMT (-05:00) Matamoros',
    id: 'AMERICA/MATAMOROS'
  },
  {
    name: 'America/Mazatlan',
    offset: '-21600',
    title: 'GMT (-06:00) Mazatlan',
    id: 'AMERICA/MAZATLAN'
  },
  {
    name: 'America/Menominee',
    offset: '-18000',
    title: 'GMT (-05:00) Menominee',
    id: 'AMERICA/MENOMINEE'
  },
  {
    name: 'America/Merida',
    offset: '-18000',
    title: 'GMT (-05:00) Merida',
    id: 'AMERICA/MERIDA'
  },
  {
    name: 'America/Metlakatla',
    offset: '-28800',
    title: 'GMT (-08:00) Metlakatla',
    id: 'AMERICA/METLAKATLA'
  },
  {
    name: 'America/Mexico_City',
    offset: '-18000',
    title: 'GMT (-05:00) Mexico City',
    id: 'AMERICA/MEXICO_CITY'
  },
  {
    name: 'America/Miquelon',
    offset: '-7200',
    title: 'GMT (-02:00) Miquelon',
    id: 'AMERICA/MIQUELON'
  },
  {
    name: 'America/Moncton',
    offset: '-10800',
    title: 'GMT (-03:00) Moncton',
    id: 'AMERICA/MONCTON'
  },
  {
    name: 'America/Monterrey',
    offset: '-18000',
    title: 'GMT (-05:00) Monterrey',
    id: 'AMERICA/MONTERREY'
  },
  {
    name: 'America/Montevideo',
    offset: '-10800',
    title: 'GMT (-03:00) Montevideo',
    id: 'AMERICA/MONTEVIDEO'
  },
  {
    name: 'America/Montserrat',
    offset: '-14400',
    title: 'GMT (-04:00) Montserrat',
    id: 'AMERICA/MONTSERRAT'
  },
  {
    name: 'America/Nassau',
    offset: '-14400',
    title: 'GMT (-04:00) Nassau',
    id: 'AMERICA/NASSAU'
  },
  {
    name: 'America/New_York',
    offset: '-14400',
    title: 'GMT (-04:00) New York',
    id: 'AMERICA/NEW_YORK'
  },
  {
    name: 'America/Nipigon',
    offset: '-14400',
    title: 'GMT (-04:00) Nipigon',
    id: 'AMERICA/NIPIGON'
  },
  {
    name: 'America/Nome',
    offset: '-28800',
    title: 'GMT (-08:00) Nome',
    id: 'AMERICA/NOME'
  },
  {
    name: 'America/Noronha',
    offset: '-7200',
    title: 'GMT (-02:00) Noronha',
    id: 'AMERICA/NORONHA'
  },
  {
    name: 'America/North_Dakota/Beulah',
    offset: '-18000',
    title: 'GMT (-05:00) Beulah',
    id: 'AMERICA/NORTH_DAKOTA/BEULAH'
  },
  {
    name: 'America/North_Dakota/Center',
    offset: '-18000',
    title: 'GMT (-05:00) Center',
    id: 'AMERICA/NORTH_DAKOTA/CENTER'
  },
  {
    name: 'America/North_Dakota/New_Salem',
    offset: '-18000',
    title: 'GMT (-05:00) New Salem',
    id: 'AMERICA/NORTH_DAKOTA/NEW_SALEM'
  },
  {
    name: 'America/Nuuk',
    offset: '-7200',
    title: 'GMT (-02:00) Nuuk'
  },
  {
    name: 'America/Ojinaga',
    offset: '-21600',
    title: 'GMT (-06:00) Ojinaga',
    id: 'AMERICA/OJINAGA'
  },
  {
    name: 'America/Panama',
    offset: '-18000',
    title: 'GMT (-05:00) Panama',
    id: 'AMERICA/PANAMA'
  },
  {
    name: 'America/Pangnirtung',
    offset: '-14400',
    title: 'GMT (-04:00) Pangnirtung',
    id: 'AMERICA/PANGNIRTUNG'
  },
  {
    name: 'America/Paramaribo',
    offset: '-10800',
    title: 'GMT (-03:00) Paramaribo',
    id: 'AMERICA/PARAMARIBO'
  },
  {
    name: 'America/Phoenix',
    offset: '-25200',
    title: 'GMT (-07:00) Phoenix',
    id: 'AMERICA/PHOENIX'
  },
  {
    name: 'America/Port-au-Prince',
    offset: '-14400',
    title: 'GMT (-04:00) Port-au-Prince',
    id: 'AMERICA/PORT-AU-PRINCE'
  },
  {
    name: 'America/Port_of_Spain',
    offset: '-14400',
    title: 'GMT (-04:00) Port of Spain',
    id: 'AMERICA/PORT_OF_SPAIN'
  },
  {
    name: 'America/Porto_Velho',
    offset: '-14400',
    title: 'GMT (-04:00) Porto Velho',
    id: 'AMERICA/PORTO_VELHO'
  },
  {
    name: 'America/Puerto_Rico',
    offset: '-14400',
    title: 'GMT (-04:00) Puerto Rico',
    id: 'AMERICA/PUERTO_RICO'
  },
  {
    name: 'America/Punta_Arenas',
    offset: '-10800',
    title: 'GMT (-03:00) Punta Arenas',
    id: 'AMERICA/PUNTA_ARENAS'
  },
  {
    name: 'America/Rainy_River',
    offset: '-18000',
    title: 'GMT (-05:00) Rainy River',
    id: 'AMERICA/RAINY_RIVER'
  },
  {
    name: 'America/Rankin_Inlet',
    offset: '-18000',
    title: 'GMT (-05:00) Rankin Inlet',
    id: 'AMERICA/RANKIN_INLET'
  },
  {
    name: 'America/Recife',
    offset: '-10800',
    title: 'GMT (-03:00) Recife',
    id: 'AMERICA/RECIFE'
  },
  {
    name: 'America/Regina',
    offset: '-21600',
    title: 'GMT (-06:00) Regina',
    id: 'AMERICA/REGINA'
  },
  {
    name: 'America/Resolute',
    offset: '-18000',
    title: 'GMT (-05:00) Resolute',
    id: 'AMERICA/RESOLUTE'
  },
  {
    name: 'America/Rio_Branco',
    offset: '-18000',
    title: 'GMT (-05:00) Rio Branco',
    id: 'AMERICA/RIO_BRANCO'
  },
  {
    name: 'America/Santarem',
    offset: '-10800',
    title: 'GMT (-03:00) Santarem',
    id: 'AMERICA/SANTAREM'
  },
  {
    name: 'America/Santiago',
    offset: '-14400',
    title: 'GMT (-04:00) Santiago',
    id: 'AMERICA/SANTIAGO'
  },
  {
    name: 'America/Santo_Domingo',
    offset: '-14400',
    title: 'GMT (-04:00) Santo Domingo',
    id: 'AMERICA/SANTO_DOMINGO'
  },
  {
    name: 'America/Sao_Paulo',
    offset: '-10800',
    title: 'GMT (-03:00) Sao Paulo',
    id: 'AMERICA/SAO_PAULO'
  },
  {
    name: 'America/Scoresbysund',
    offset: '0',
    title: 'GMT (+00:00) Scoresbysund',
    id: 'AMERICA/SCORESBYSUND'
  },
  {
    name: 'America/Sitka',
    offset: '-28800',
    title: 'GMT (-08:00) Sitka',
    id: 'AMERICA/SITKA'
  },
  {
    name: 'America/St_Barthelemy',
    offset: '-14400',
    title: 'GMT (-04:00) St Barthelemy',
    id: 'AMERICA/ST_BARTHELEMY'
  },
  {
    name: 'America/St_Johns',
    offset: '-9000',
    title: 'GMT (-02:30) St Johns',
    id: 'AMERICA/ST_JOHNS'
  },
  {
    name: 'America/St_Kitts',
    offset: '-14400',
    title: 'GMT (-04:00) St Kitts',
    id: 'AMERICA/ST_KITTS'
  },
  {
    name: 'America/St_Lucia',
    offset: '-14400',
    title: 'GMT (-04:00) St Lucia',
    id: 'AMERICA/ST_LUCIA'
  },
  {
    name: 'America/St_Thomas',
    offset: '-14400',
    title: 'GMT (-04:00) St Thomas',
    id: 'AMERICA/ST_THOMAS'
  },
  {
    name: 'America/St_Vincent',
    offset: '-14400',
    title: 'GMT (-04:00) St Vincent',
    id: 'AMERICA/ST_VINCENT'
  },
  {
    name: 'America/Swift_Current',
    offset: '-21600',
    title: 'GMT (-06:00) Swift Current',
    id: 'AMERICA/SWIFT_CURRENT'
  },
  {
    name: 'America/Tegucigalpa',
    offset: '-21600',
    title: 'GMT (-06:00) Tegucigalpa',
    id: 'AMERICA/TEGUCIGALPA'
  },
  {
    name: 'America/Thule',
    offset: '-10800',
    title: 'GMT (-03:00) Thule',
    id: 'AMERICA/THULE'
  },
  {
    name: 'America/Thunder_Bay',
    offset: '-14400',
    title: 'GMT (-04:00) Thunder Bay',
    id: 'AMERICA/THUNDER_BAY'
  },
  {
    name: 'America/Tijuana',
    offset: '-25200',
    title: 'GMT (-07:00) Tijuana',
    id: 'AMERICA/TIJUANA'
  },
  {
    name: 'America/Toronto',
    offset: '-14400',
    title: 'GMT (-04:00) Toronto',
    id: 'AMERICA/TORONTO'
  },
  {
    name: 'America/Tortola',
    offset: '-14400',
    title: 'GMT (-04:00) Tortola',
    id: 'AMERICA/TORTOLA'
  },
  {
    name: 'America/Vancouver',
    offset: '-25200',
    title: 'GMT (-07:00) Vancouver',
    id: 'AMERICA/VANCOUVER'
  },
  {
    name: 'America/Whitehorse',
    offset: '-25200',
    title: 'GMT (-07:00) Whitehorse',
    id: 'AMERICA/WHITEHORSE'
  },
  {
    name: 'America/Winnipeg',
    offset: '-18000',
    title: 'GMT (-05:00) Winnipeg',
    id: 'AMERICA/WINNIPEG'
  },
  {
    name: 'America/Yakutat',
    offset: '-28800',
    title: 'GMT (-08:00) Yakutat',
    id: 'AMERICA/YAKUTAT'
  },
  {
    name: 'America/Yellowknife',
    offset: '-21600',
    title: 'GMT (-06:00) Yellowknife',
    id: 'AMERICA/YELLOWKNIFE'
  },
  {
    name: 'Antarctica/Casey',
    offset: '28800',
    title: 'GMT (+08:00) Casey',
    id: 'ANTARCTICA/CASEY'
  },
  {
    name: 'Antarctica/Davis',
    offset: '25200',
    title: 'GMT (+07:00) Davis',
    id: 'ANTARCTICA/DAVIS'
  },
  {
    name: 'Antarctica/DumontDUrville',
    offset: '36000',
    title: 'GMT (+10:00) DumontDUrville',
    id: 'ANTARCTICA/DUMONTDURVILLE'
  },
  {
    name: 'Antarctica/Macquarie',
    offset: '39600',
    title: 'GMT (+11:00) Macquarie',
    id: 'ANTARCTICA/MACQUARIE'
  },
  {
    name: 'Antarctica/Mawson',
    offset: '18000',
    title: 'GMT (+05:00) Mawson',
    id: 'ANTARCTICA/MAWSON'
  },
  {
    name: 'Antarctica/McMurdo',
    offset: '43200',
    title: 'GMT (+12:00) McMurdo',
    id: 'ANTARCTICA/MCMURDO'
  },
  {
    name: 'Antarctica/Palmer',
    offset: '-10800',
    title: 'GMT (-03:00) Palmer',
    id: 'ANTARCTICA/PALMER'
  },
  {
    name: 'Antarctica/Rothera',
    offset: '-10800',
    title: 'GMT (-03:00) Rothera',
    id: 'ANTARCTICA/ROTHERA'
  },
  {
    name: 'Antarctica/Syowa',
    offset: '10800',
    title: 'GMT (+03:00) Syowa',
    id: 'ANTARCTICA/SYOWA'
  },
  {
    name: 'Antarctica/Troll',
    offset: '7200',
    title: 'GMT (+02:00) Troll',
    id: 'ANTARCTICA/TROLL'
  },
  {
    name: 'Antarctica/Vostok',
    offset: '21600',
    title: 'GMT (+06:00) Vostok',
    id: 'ANTARCTICA/VOSTOK'
  },
  {
    name: 'Arctic/Longyearbyen',
    offset: '7200',
    title: 'GMT (+02:00) Longyearbyen',
    id: 'ARCTIC/LONGYEARBYEN'
  },
  {
    name: 'Asia/Aden',
    offset: '10800',
    title: 'GMT (+03:00) Aden',
    id: 'ASIA/ADEN'
  },
  {
    name: 'Asia/Almaty',
    offset: '21600',
    title: 'GMT (+06:00) Almaty',
    id: 'ASIA/ALMATY'
  },
  {
    name: 'Asia/Amman',
    offset: '10800',
    title: 'GMT (+03:00) Amman',
    id: 'ASIA/AMMAN'
  },
  {
    name: 'Asia/Anadyr',
    offset: '43200',
    title: 'GMT (+12:00) Anadyr',
    id: 'ASIA/ANADYR'
  },
  {
    name: 'Asia/Aqtau',
    offset: '18000',
    title: 'GMT (+05:00) Aqtau',
    id: 'ASIA/AQTAU'
  },
  {
    name: 'Asia/Aqtobe',
    offset: '18000',
    title: 'GMT (+05:00) Aqtobe',
    id: 'ASIA/AQTOBE'
  },
  {
    name: 'Asia/Ashgabat',
    offset: '18000',
    title: 'GMT (+05:00) Ashgabat',
    id: 'ASIA/ASHGABAT'
  },
  {
    name: 'Asia/Atyrau',
    offset: '18000',
    title: 'GMT (+05:00) Atyrau',
    id: 'ASIA/ATYRAU'
  },
  {
    name: 'Asia/Baghdad',
    offset: '10800',
    title: 'GMT (+03:00) Baghdad',
    id: 'ASIA/BAGHDAD'
  },
  {
    name: 'Asia/Bahrain',
    offset: '10800',
    title: 'GMT (+03:00) Bahrain',
    id: 'ASIA/BAHRAIN'
  },
  {
    name: 'Asia/Baku',
    offset: '14400',
    title: 'GMT (+04:00) Baku',
    id: 'ASIA/BAKU'
  },
  {
    name: 'Asia/Bangkok',
    offset: '25200',
    title: 'GMT (+07:00) Bangkok',
    id: 'ASIA/BANGKOK'
  },
  {
    name: 'Asia/Barnaul',
    offset: '25200',
    title: 'GMT (+07:00) Barnaul',
    id: 'ASIA/BARNAUL'
  },
  {
    name: 'Asia/Beirut',
    offset: '10800',
    title: 'GMT (+03:00) Beirut',
    id: 'ASIA/BEIRUT'
  },
  {
    name: 'Asia/Bishkek',
    offset: '21600',
    title: 'GMT (+06:00) Bishkek',
    id: 'ASIA/BISHKEK'
  },
  {
    name: 'Asia/Brunei',
    offset: '28800',
    title: 'GMT (+08:00) Brunei',
    id: 'ASIA/BRUNEI'
  },
  {
    name: 'Asia/Chita',
    offset: '32400',
    title: 'GMT (+09:00) Chita',
    id: 'ASIA/CHITA'
  },
  {
    name: 'Asia/Choibalsan',
    offset: '28800',
    title: 'GMT (+08:00) Choibalsan',
    id: 'ASIA/CHOIBALSAN'
  },
  {
    name: 'Asia/Colombo',
    offset: '19800',
    title: 'GMT (+05:30) Colombo',
    id: 'ASIA/COLOMBO'
  },
  {
    name: 'Asia/Damascus',
    offset: '10800',
    title: 'GMT (+03:00) Damascus',
    id: 'ASIA/DAMASCUS'
  },
  {
    name: 'Asia/Dhaka',
    offset: '21600',
    title: 'GMT (+06:00) Dhaka',
    id: 'ASIA/DHAKA'
  },
  {
    name: 'Asia/Dili',
    offset: '32400',
    title: 'GMT (+09:00) Dili',
    id: 'ASIA/DILI'
  },
  {
    name: 'Asia/Dubai',
    offset: '14400',
    title: 'GMT (+04:00) Dubai',
    id: 'ASIA/DUBAI'
  },
  {
    name: 'Asia/Dushanbe',
    offset: '18000',
    title: 'GMT (+05:00) Dushanbe',
    id: 'ASIA/DUSHANBE'
  },
  {
    name: 'Asia/Famagusta',
    offset: '10800',
    title: 'GMT (+03:00) Famagusta',
    id: 'ASIA/FAMAGUSTA'
  },
  {
    name: 'Asia/Gaza',
    offset: '10800',
    title: 'GMT (+03:00) Gaza',
    id: 'ASIA/GAZA'
  },
  {
    name: 'Asia/Hebron',
    offset: '10800',
    title: 'GMT (+03:00) Hebron',
    id: 'ASIA/HEBRON'
  },
  {
    name: 'Asia/Ho_Chi_Minh',
    offset: '25200',
    title: 'GMT (+07:00) Ho Chi Minh',
    id: 'ASIA/HO_CHI_MINH'
  },
  {
    name: 'Asia/Hong_Kong',
    offset: '28800',
    title: 'GMT (+08:00) Hong Kong',
    id: 'ASIA/HONG_KONG'
  },
  {
    name: 'Asia/Hovd',
    offset: '25200',
    title: 'GMT (+07:00) Hovd',
    id: 'ASIA/HOVD'
  },
  {
    name: 'Asia/Irkutsk',
    offset: '28800',
    title: 'GMT (+08:00) Irkutsk',
    id: 'ASIA/IRKUTSK'
  },
  {
    name: 'Asia/Jakarta',
    offset: '25200',
    title: 'GMT (+07:00) Jakarta',
    id: 'ASIA/JAKARTA'
  },
  {
    name: 'Asia/Jayapura',
    offset: '32400',
    title: 'GMT (+09:00) Jayapura',
    id: 'ASIA/JAYAPURA'
  },
  {
    name: 'Asia/Jerusalem',
    offset: '10800',
    title: 'GMT (+03:00) Jerusalem',
    id: 'ASIA/JERUSALEM'
  },
  {
    name: 'Asia/Kabul',
    offset: '16200',
    title: 'GMT (+04:30) Kabul',
    id: 'ASIA/KABUL'
  },
  {
    name: 'Asia/Kamchatka',
    offset: '43200',
    title: 'GMT (+12:00) Kamchatka',
    id: 'ASIA/KAMCHATKA'
  },
  {
    name: 'Asia/Karachi',
    offset: '18000',
    title: 'GMT (+05:00) Karachi',
    id: 'ASIA/KARACHI'
  },
  {
    name: 'Asia/Kathmandu',
    offset: '20700',
    title: 'GMT (+05:45) Kathmandu',
    id: 'ASIA/KATHMANDU'
  },
  {
    name: 'Asia/Khandyga',
    offset: '32400',
    title: 'GMT (+09:00) Khandyga',
    id: 'ASIA/KHANDYGA'
  },
  {
    name: 'Asia/Kolkata',
    offset: '19800',
    title: 'GMT (+05:30) Kolkata',
    id: 'ASIA/KOLKATA'
  },
  {
    name: 'Asia/Krasnoyarsk',
    offset: '25200',
    title: 'GMT (+07:00) Krasnoyarsk',
    id: 'ASIA/KRASNOYARSK'
  },
  {
    name: 'Asia/Kuala_Lumpur',
    offset: '28800',
    title: 'GMT (+08:00) Kuala Lumpur',
    id: 'ASIA/KUALA_LUMPUR'
  },
  {
    name: 'Asia/Kuching',
    offset: '28800',
    title: 'GMT (+08:00) Kuching',
    id: 'ASIA/KUCHING'
  },
  {
    name: 'Asia/Kuwait',
    offset: '10800',
    title: 'GMT (+03:00) Kuwait',
    id: 'ASIA/KUWAIT'
  },
  {
    name: 'Asia/Macau',
    offset: '28800',
    title: 'GMT (+08:00) Macau',
    id: 'ASIA/MACAU'
  },
  {
    name: 'Asia/Magadan',
    offset: '39600',
    title: 'GMT (+11:00) Magadan',
    id: 'ASIA/MAGADAN'
  },
  {
    name: 'Asia/Makassar',
    offset: '28800',
    title: 'GMT (+08:00) Makassar',
    id: 'ASIA/MAKASSAR'
  },
  {
    name: 'Asia/Manila',
    offset: '28800',
    title: 'GMT (+08:00) Manila',
    id: 'ASIA/MANILA'
  },
  {
    name: 'Asia/Muscat',
    offset: '14400',
    title: 'GMT (+04:00) Muscat',
    id: 'ASIA/MUSCAT'
  },
  {
    name: 'Asia/Nicosia',
    offset: '10800',
    title: 'GMT (+03:00) Nicosia',
    id: 'ASIA/NICOSIA'
  },
  {
    name: 'Asia/Novokuznetsk',
    offset: '25200',
    title: 'GMT (+07:00) Novokuznetsk',
    id: 'ASIA/NOVOKUZNETSK'
  },
  {
    name: 'Asia/Novosibirsk',
    offset: '25200',
    title: 'GMT (+07:00) Novosibirsk',
    id: 'ASIA/NOVOSIBIRSK'
  },
  {
    name: 'Asia/Omsk',
    offset: '21600',
    title: 'GMT (+06:00) Omsk',
    id: 'ASIA/OMSK'
  },
  {
    name: 'Asia/Oral',
    offset: '18000',
    title: 'GMT (+05:00) Oral',
    id: 'ASIA/ORAL'
  },
  {
    name: 'Asia/Phnom_Penh',
    offset: '25200',
    title: 'GMT (+07:00) Phnom Penh',
    id: 'ASIA/PHNOM_PENH'
  },
  {
    name: 'Asia/Pontianak',
    offset: '25200',
    title: 'GMT (+07:00) Pontianak',
    id: 'ASIA/PONTIANAK'
  },
  {
    name: 'Asia/Pyongyang',
    offset: '32400',
    title: 'GMT (+09:00) Pyongyang',
    id: 'ASIA/PYONGYANG'
  },
  {
    name: 'Asia/Qatar',
    offset: '10800',
    title: 'GMT (+03:00) Qatar',
    id: 'ASIA/QATAR'
  },
  {
    name: 'Asia/Qostanay',
    offset: '21600',
    title: 'GMT (+06:00) Qostanay',
    id: 'ASIA/QOSTANAY'
  },
  {
    name: 'Asia/Qyzylorda',
    offset: '18000',
    title: 'GMT (+05:00) Qyzylorda',
    id: 'ASIA/QYZYLORDA'
  },
  {
    name: 'Asia/Riyadh',
    offset: '10800',
    title: 'GMT (+03:00) Riyadh',
    id: 'ASIA/RIYADH'
  },
  {
    name: 'Asia/Sakhalin',
    offset: '39600',
    title: 'GMT (+11:00) Sakhalin',
    id: 'ASIA/SAKHALIN'
  },
  {
    name: 'Asia/Samarkand',
    offset: '18000',
    title: 'GMT (+05:00) Samarkand',
    id: 'ASIA/SAMARKAND'
  },
  {
    name: 'Asia/Seoul',
    offset: '32400',
    title: 'GMT (+09:00) Seoul',
    id: 'ASIA/SEOUL'
  },
  {
    name: 'Asia/Shanghai',
    offset: '28800',
    title: 'GMT (+08:00) Shanghai',
    id: 'ASIA/SHANGHAI'
  },
  {
    name: 'Asia/Singapore',
    offset: '28800',
    title: 'GMT (+08:00) Singapore',
    id: 'ASIA/SINGAPORE'
  },
  {
    name: 'Asia/Srednekolymsk',
    offset: '39600',
    title: 'GMT (+11:00) Srednekolymsk',
    id: 'ASIA/SREDNEKOLYMSK'
  },
  {
    name: 'Asia/Taipei',
    offset: '28800',
    title: 'GMT (+08:00) Taipei',
    id: 'ASIA/TAIPEI'
  },
  {
    name: 'Asia/Tashkent',
    offset: '18000',
    title: 'GMT (+05:00) Tashkent',
    id: 'ASIA/TASHKENT'
  },
  {
    name: 'Asia/Tbilisi',
    offset: '14400',
    title: 'GMT (+04:00) Tbilisi',
    id: 'ASIA/TBILISI'
  },
  {
    name: 'Asia/Tehran',
    offset: '16200',
    title: 'GMT (+04:30) Tehran',
    id: 'ASIA/TEHRAN'
  },
  {
    name: 'Asia/Thimphu',
    offset: '21600',
    title: 'GMT (+06:00) Thimphu',
    id: 'ASIA/THIMPHU'
  },
  {
    name: 'Asia/Tokyo',
    offset: '32400',
    title: 'GMT (+09:00) Tokyo',
    id: 'ASIA/TOKYO'
  },
  {
    name: 'Asia/Tomsk',
    offset: '25200',
    title: 'GMT (+07:00) Tomsk',
    id: 'ASIA/TOMSK'
  },
  {
    name: 'Asia/Ulaanbaatar',
    offset: '28800',
    title: 'GMT (+08:00) Ulaanbaatar',
    id: 'ASIA/ULAANBAATAR'
  },
  {
    name: 'Asia/Urumqi',
    offset: '21600',
    title: 'GMT (+06:00) Urumqi',
    id: 'ASIA/URUMQI'
  },
  {
    name: 'Asia/Ust-Nera',
    offset: '36000',
    title: 'GMT (+10:00) Ust-Nera',
    id: 'ASIA/UST-NERA'
  },
  {
    name: 'Asia/Vientiane',
    offset: '25200',
    title: 'GMT (+07:00) Vientiane',
    id: 'ASIA/VIENTIANE'
  },
  {
    name: 'Asia/Vladivostok',
    offset: '36000',
    title: 'GMT (+10:00) Vladivostok',
    id: 'ASIA/VLADIVOSTOK'
  },
  {
    name: 'Asia/Yakutsk',
    offset: '32400',
    title: 'GMT (+09:00) Yakutsk',
    id: 'ASIA/YAKUTSK'
  },
  {
    name: 'Asia/Yangon',
    offset: '23400',
    title: 'GMT (+06:30) Yangon',
    id: 'ASIA/YANGON'
  },
  {
    name: 'Asia/Yekaterinburg',
    offset: '18000',
    title: 'GMT (+05:00) Yekaterinburg',
    id: 'ASIA/YEKATERINBURG'
  },
  {
    name: 'Asia/Yerevan',
    offset: '14400',
    title: 'GMT (+04:00) Yerevan',
    id: 'ASIA/YEREVAN'
  },
  {
    name: 'Atlantic/Azores',
    offset: '0',
    title: 'GMT (+00:00) Azores',
    id: 'ATLANTIC/AZORES'
  },
  {
    name: 'Atlantic/Bermuda',
    offset: '-10800',
    title: 'GMT (-03:00) Bermuda',
    id: 'ATLANTIC/BERMUDA'
  },
  {
    name: 'Atlantic/Canary',
    offset: '3600',
    title: 'GMT (+01:00) Canary',
    id: 'ATLANTIC/CANARY'
  },
  {
    name: 'Atlantic/Cape_Verde',
    offset: '-3600',
    title: 'GMT (-01:00) Cape Verde',
    id: 'ATLANTIC/CAPE_VERDE'
  },
  {
    name: 'Atlantic/Faroe',
    offset: '3600',
    title: 'GMT (+01:00) Faroe',
    id: 'ATLANTIC/FAROE'
  },
  {
    name: 'Atlantic/Madeira',
    offset: '3600',
    title: 'GMT (+01:00) Madeira',
    id: 'ATLANTIC/MADEIRA'
  },
  {
    name: 'Atlantic/Reykjavik',
    offset: '0',
    title: 'GMT (+00:00) Reykjavik',
    id: 'ATLANTIC/REYKJAVIK'
  },
  {
    name: 'Atlantic/South_Georgia',
    offset: '-7200',
    title: 'GMT (-02:00) South Georgia',
    id: 'ATLANTIC/SOUTH_GEORGIA'
  },
  {
    name: 'Atlantic/St_Helena',
    offset: '0',
    title: 'GMT (+00:00) St Helena',
    id: 'ATLANTIC/ST_HELENA'
  },
  {
    name: 'Atlantic/Stanley',
    offset: '-10800',
    title: 'GMT (-03:00) Stanley',
    id: 'ATLANTIC/STANLEY'
  },
  {
    name: 'Australia/Adelaide',
    offset: '34200',
    title: 'GMT (+09:30) Adelaide',
    id: 'AUSTRALIA/ADELAIDE'
  },
  {
    name: 'Australia/Brisbane',
    offset: '36000',
    title: 'GMT (+10:00) Brisbane',
    id: 'AUSTRALIA/BRISBANE'
  },
  {
    name: 'Australia/Broken_Hill',
    offset: '34200',
    title: 'GMT (+09:30) Broken Hill',
    id: 'AUSTRALIA/BROKEN_HILL'
  },
  {
    name: 'Australia/Currie',
    offset: '36000',
    title: 'GMT (+10:00) Currie',
    id: 'AUSTRALIA/CURRIE'
  },
  {
    name: 'Australia/Darwin',
    offset: '34200',
    title: 'GMT (+09:30) Darwin',
    id: 'AUSTRALIA/DARWIN'
  },
  {
    name: 'Australia/Eucla',
    offset: '31500',
    title: 'GMT (+08:45) Eucla',
    id: 'AUSTRALIA/EUCLA'
  },
  {
    name: 'Australia/Hobart',
    offset: '36000',
    title: 'GMT (+10:00) Hobart',
    id: 'AUSTRALIA/HOBART'
  },
  {
    name: 'Australia/Lindeman',
    offset: '36000',
    title: 'GMT (+10:00) Lindeman',
    id: 'AUSTRALIA/LINDEMAN'
  },
  {
    name: 'Australia/Lord_Howe',
    offset: '37800',
    title: 'GMT (+10:30) Lord Howe',
    id: 'AUSTRALIA/LORD_HOWE'
  },
  {
    name: 'Australia/Melbourne',
    offset: '36000',
    title: 'GMT (+10:00) Melbourne',
    id: 'AUSTRALIA/MELBOURNE'
  },
  {
    name: 'Australia/Perth',
    offset: '28800',
    title: 'GMT (+08:00) Perth',
    id: 'AUSTRALIA/PERTH'
  },
  {
    name: 'Australia/Sydney',
    offset: '36000',
    title: 'GMT (+10:00) Sydney',
    id: 'AUSTRALIA/SYDNEY'
  },
  {
    name: 'Europe/Amsterdam',
    offset: '7200',
    title: 'GMT (+02:00) Amsterdam',
    id: 'EUROPE/AMSTERDAM'
  },
  {
    name: 'Europe/Andorra',
    offset: '7200',
    title: 'GMT (+02:00) Andorra',
    id: 'EUROPE/ANDORRA'
  },
  {
    name: 'Europe/Astrakhan',
    offset: '14400',
    title: 'GMT (+04:00) Astrakhan',
    id: 'EUROPE/ASTRAKHAN'
  },
  {
    name: 'Europe/Athens',
    offset: '10800',
    title: 'GMT (+03:00) Athens',
    id: 'EUROPE/ATHENS'
  },
  {
    name: 'Europe/Belgrade',
    offset: '7200',
    title: 'GMT (+02:00) Belgrade',
    id: 'EUROPE/BELGRADE'
  },
  {
    name: 'Europe/Berlin',
    offset: '7200',
    title: 'GMT (+02:00) Berlin',
    id: 'EUROPE/BERLIN'
  },
  {
    name: 'Europe/Bratislava',
    offset: '7200',
    title: 'GMT (+02:00) Bratislava',
    id: 'EUROPE/BRATISLAVA'
  },
  {
    name: 'Europe/Brussels',
    offset: '7200',
    title: 'GMT (+02:00) Brussels',
    id: 'EUROPE/BRUSSELS'
  },
  {
    name: 'Europe/Bucharest',
    offset: '10800',
    title: 'GMT (+03:00) Bucharest',
    id: 'EUROPE/BUCHAREST'
  },
  {
    name: 'Europe/Budapest',
    offset: '7200',
    title: 'GMT (+02:00) Budapest',
    id: 'EUROPE/BUDAPEST'
  },
  {
    name: 'Europe/Busingen',
    offset: '7200',
    title: 'GMT (+02:00) Busingen',
    id: 'EUROPE/BUSINGEN'
  },
  {
    name: 'Europe/Chisinau',
    offset: '10800',
    title: 'GMT (+03:00) Chisinau',
    id: 'EUROPE/CHISINAU'
  },
  {
    name: 'Europe/Copenhagen',
    offset: '7200',
    title: 'GMT (+02:00) Copenhagen',
    id: 'EUROPE/COPENHAGEN'
  },
  {
    name: 'Europe/Dublin',
    offset: '3600',
    title: 'GMT (+01:00) Dublin',
    id: 'EUROPE/DUBLIN'
  },
  {
    name: 'Europe/Gibraltar',
    offset: '7200',
    title: 'GMT (+02:00) Gibraltar',
    id: 'EUROPE/GIBRALTAR'
  },
  {
    name: 'Europe/Guernsey',
    offset: '3600',
    title: 'GMT (+01:00) Guernsey',
    id: 'EUROPE/GUERNSEY'
  },
  {
    name: 'Europe/Helsinki',
    offset: '10800',
    title: 'GMT (+03:00) Helsinki',
    id: 'EUROPE/HELSINKI'
  },
  {
    name: 'Europe/Isle_of_Man',
    offset: '3600',
    title: 'GMT (+01:00) Isle of Man',
    id: 'EUROPE/ISLE_OF_MAN'
  },
  {
    name: 'Europe/Istanbul',
    offset: '10800',
    title: 'GMT (+03:00) Istanbul',
    id: 'EUROPE/ISTANBUL'
  },
  {
    name: 'Europe/Jersey',
    offset: '3600',
    title: 'GMT (+01:00) Jersey',
    id: 'EUROPE/JERSEY'
  },
  {
    name: 'Europe/Kaliningrad',
    offset: '7200',
    title: 'GMT (+02:00) Kaliningrad',
    id: 'EUROPE/KALININGRAD'
  },
  {
    name: 'Europe/Kiev',
    offset: '10800',
    title: 'GMT (+03:00) Kiev',
    id: 'EUROPE/KIEV'
  },
  {
    name: 'Europe/Kirov',
    offset: '10800',
    title: 'GMT (+03:00) Kirov',
    id: 'EUROPE/KIROV'
  },
  {
    name: 'Europe/Lisbon',
    offset: '3600',
    title: 'GMT (+01:00) Lisbon',
    id: 'EUROPE/LISBON'
  },
  {
    name: 'Europe/Ljubljana',
    offset: '7200',
    title: 'GMT (+02:00) Ljubljana',
    id: 'EUROPE/LJUBLJANA'
  },
  {
    name: 'Europe/London',
    offset: '3600',
    title: 'GMT (+01:00) London',
    id: 'EUROPE/LONDON'
  },
  {
    name: 'Europe/Luxembourg',
    offset: '7200',
    title: 'GMT (+02:00) Luxembourg',
    id: 'EUROPE/LUXEMBOURG'
  },
  {
    name: 'Europe/Madrid',
    offset: '7200',
    title: 'GMT (+02:00) Madrid',
    id: 'EUROPE/MADRID'
  },
  {
    name: 'Europe/Malta',
    offset: '7200',
    title: 'GMT (+02:00) Malta',
    id: 'EUROPE/MALTA'
  },
  {
    name: 'Europe/Mariehamn',
    offset: '10800',
    title: 'GMT (+03:00) Mariehamn',
    id: 'EUROPE/MARIEHAMN'
  },
  {
    name: 'Europe/Minsk',
    offset: '10800',
    title: 'GMT (+03:00) Minsk',
    id: 'EUROPE/MINSK'
  },
  {
    name: 'Europe/Monaco',
    offset: '7200',
    title: 'GMT (+02:00) Monaco',
    id: 'EUROPE/MONACO'
  },
  {
    name: 'Europe/Moscow',
    offset: '10800',
    title: 'GMT (+03:00) Moscow',
    id: 'EUROPE/MOSCOW'
  },
  {
    name: 'Europe/Oslo',
    offset: '7200',
    title: 'GMT (+02:00) Oslo',
    id: 'EUROPE/OSLO'
  },
  {
    name: 'Europe/Paris',
    offset: '7200',
    title: 'GMT (+02:00) Paris',
    id: 'EUROPE/PARIS'
  },
  {
    name: 'Europe/Podgorica',
    offset: '7200',
    title: 'GMT (+02:00) Podgorica',
    id: 'EUROPE/PODGORICA'
  },
  {
    name: 'Europe/Prague',
    offset: '7200',
    title: 'GMT (+02:00) Prague',
    id: 'EUROPE/PRAGUE'
  },
  {
    name: 'Europe/Riga',
    offset: '10800',
    title: 'GMT (+03:00) Riga',
    id: 'EUROPE/RIGA'
  },
  {
    name: 'Europe/Rome',
    offset: '7200',
    title: 'GMT (+02:00) Rome',
    id: 'EUROPE/ROME'
  },
  {
    name: 'Europe/Samara',
    offset: '14400',
    title: 'GMT (+04:00) Samara',
    id: 'EUROPE/SAMARA'
  },
  {
    name: 'Europe/San_Marino',
    offset: '7200',
    title: 'GMT (+02:00) San Marino',
    id: 'EUROPE/SAN_MARINO'
  },
  {
    name: 'Europe/Sarajevo',
    offset: '7200',
    title: 'GMT (+02:00) Sarajevo',
    id: 'EUROPE/SARAJEVO'
  },
  {
    name: 'Europe/Saratov',
    offset: '14400',
    title: 'GMT (+04:00) Saratov',
    id: 'EUROPE/SARATOV'
  },
  {
    name: 'Europe/Simferopol',
    offset: '10800',
    title: 'GMT (+03:00) Simferopol',
    id: 'EUROPE/SIMFEROPOL'
  },
  {
    name: 'Europe/Skopje',
    offset: '7200',
    title: 'GMT (+02:00) Skopje',
    id: 'EUROPE/SKOPJE'
  },
  {
    name: 'Europe/Sofia',
    offset: '10800',
    title: 'GMT (+03:00) Sofia',
    id: 'EUROPE/SOFIA'
  },
  {
    name: 'Europe/Stockholm',
    offset: '7200',
    title: 'GMT (+02:00) Stockholm',
    id: 'EUROPE/STOCKHOLM'
  },
  {
    name: 'Europe/Tallinn',
    offset: '10800',
    title: 'GMT (+03:00) Tallinn',
    id: 'EUROPE/TALLINN'
  },
  {
    name: 'Europe/Tirane',
    offset: '7200',
    title: 'GMT (+02:00) Tirane',
    id: 'EUROPE/TIRANE'
  },
  {
    name: 'Europe/Ulyanovsk',
    offset: '14400',
    title: 'GMT (+04:00) Ulyanovsk',
    id: 'EUROPE/ULYANOVSK'
  },
  {
    name: 'Europe/Uzhgorod',
    offset: '10800',
    title: 'GMT (+03:00) Uzhgorod',
    id: 'EUROPE/UZHGOROD'
  },
  {
    name: 'Europe/Vaduz',
    offset: '7200',
    title: 'GMT (+02:00) Vaduz',
    id: 'EUROPE/VADUZ'
  },
  {
    name: 'Europe/Vatican',
    offset: '7200',
    title: 'GMT (+02:00) Vatican',
    id: 'EUROPE/VATICAN'
  },
  {
    name: 'Europe/Vienna',
    offset: '7200',
    title: 'GMT (+02:00) Vienna',
    id: 'EUROPE/VIENNA'
  },
  {
    name: 'Europe/Vilnius',
    offset: '10800',
    title: 'GMT (+03:00) Vilnius',
    id: 'EUROPE/VILNIUS'
  },
  {
    name: 'Europe/Volgograd',
    offset: '14400',
    title: 'GMT (+04:00) Volgograd',
    id: 'EUROPE/VOLGOGRAD'
  },
  {
    name: 'Europe/Warsaw',
    offset: '7200',
    title: 'GMT (+02:00) Warsaw',
    id: 'EUROPE/WARSAW'
  },
  {
    name: 'Europe/Zagreb',
    offset: '7200',
    title: 'GMT (+02:00) Zagreb',
    id: 'EUROPE/ZAGREB'
  },
  {
    name: 'Europe/Zaporozhye',
    offset: '10800',
    title: 'GMT (+03:00) Zaporozhye',
    id: 'EUROPE/ZAPOROZHYE'
  },
  {
    name: 'Europe/Zurich',
    offset: '7200',
    title: 'GMT (+02:00) Zurich',
    id: 'EUROPE/ZURICH'
  },
  {
    name: 'Indian/Antananarivo',
    offset: '10800',
    title: 'GMT (+03:00) Antananarivo',
    id: 'INDIAN/ANTANANARIVO'
  },
  {
    name: 'Indian/Chagos',
    offset: '21600',
    title: 'GMT (+06:00) Chagos',
    id: 'INDIAN/CHAGOS'
  },
  {
    name: 'Indian/Christmas',
    offset: '25200',
    title: 'GMT (+07:00) Christmas',
    id: 'INDIAN/CHRISTMAS'
  },
  {
    name: 'Indian/Cocos',
    offset: '23400',
    title: 'GMT (+06:30) Cocos',
    id: 'INDIAN/COCOS'
  },
  {
    name: 'Indian/Comoro',
    offset: '10800',
    title: 'GMT (+03:00) Comoro',
    id: 'INDIAN/COMORO'
  },
  {
    name: 'Indian/Kerguelen',
    offset: '18000',
    title: 'GMT (+05:00) Kerguelen',
    id: 'INDIAN/KERGUELEN'
  },
  {
    name: 'Indian/Mahe',
    offset: '14400',
    title: 'GMT (+04:00) Mahe',
    id: 'INDIAN/MAHE'
  },
  {
    name: 'Indian/Maldives',
    offset: '18000',
    title: 'GMT (+05:00) Maldives',
    id: 'INDIAN/MALDIVES'
  },
  {
    name: 'Indian/Mauritius',
    offset: '14400',
    title: 'GMT (+04:00) Mauritius',
    id: 'INDIAN/MAURITIUS'
  },
  {
    name: 'Indian/Mayotte',
    offset: '10800',
    title: 'GMT (+03:00) Mayotte',
    id: 'INDIAN/MAYOTTE'
  },
  {
    name: 'Indian/Reunion',
    offset: '14400',
    title: 'GMT (+04:00) Reunion',
    id: 'INDIAN/REUNION'
  },
  {
    name: 'Pacific/Apia',
    offset: '46800',
    title: 'GMT (+13:00) Apia',
    id: 'PACIFIC/APIA'
  },
  {
    name: 'Pacific/Auckland',
    offset: '43200',
    title: 'GMT (+12:00) Auckland',
    id: 'PACIFIC/AUCKLAND'
  },
  {
    name: 'Pacific/Bougainville',
    offset: '39600',
    title: 'GMT (+11:00) Bougainville',
    id: 'PACIFIC/BOUGAINVILLE'
  },
  {
    name: 'Pacific/Chatham',
    offset: '45900',
    title: 'GMT (+12:45) Chatham',
    id: 'PACIFIC/CHATHAM'
  },
  {
    name: 'Pacific/Chuuk',
    offset: '36000',
    title: 'GMT (+10:00) Chuuk',
    id: 'PACIFIC/CHUUK'
  },
  {
    name: 'Pacific/Easter',
    offset: '-21600',
    title: 'GMT (-06:00) Easter',
    id: 'PACIFIC/EASTER'
  },
  {
    name: 'Pacific/Efate',
    offset: '39600',
    title: 'GMT (+11:00) Efate',
    id: 'PACIFIC/EFATE'
  },
  {
    name: 'Pacific/Enderbury',
    offset: '46800',
    title: 'GMT (+13:00) Enderbury',
    id: 'PACIFIC/ENDERBURY'
  },
  {
    name: 'Pacific/Fakaofo',
    offset: '46800',
    title: 'GMT (+13:00) Fakaofo',
    id: 'PACIFIC/FAKAOFO'
  },
  {
    name: 'Pacific/Fiji',
    offset: '43200',
    title: 'GMT (+12:00) Fiji',
    id: 'PACIFIC/FIJI'
  },
  {
    name: 'Pacific/Funafuti',
    offset: '43200',
    title: 'GMT (+12:00) Funafuti',
    id: 'PACIFIC/FUNAFUTI'
  },
  {
    name: 'Pacific/Galapagos',
    offset: '-21600',
    title: 'GMT (-06:00) Galapagos',
    id: 'PACIFIC/GALAPAGOS'
  },
  {
    name: 'Pacific/Gambier',
    offset: '-32400',
    title: 'GMT (-09:00) Gambier',
    id: 'PACIFIC/GAMBIER'
  },
  {
    name: 'Pacific/Guadalcanal',
    offset: '39600',
    title: 'GMT (+11:00) Guadalcanal',
    id: 'PACIFIC/GUADALCANAL'
  },
  {
    name: 'Pacific/Guam',
    offset: '36000',
    title: 'GMT (+10:00) Guam',
    id: 'PACIFIC/GUAM'
  },
  {
    name: 'Pacific/Honolulu',
    offset: '-36000',
    title: 'GMT (-10:00) Honolulu',
    id: 'PACIFIC/HONOLULU'
  },
  {
    name: 'Pacific/Kiritimati',
    offset: '50400',
    title: 'GMT (+14:00) Kiritimati',
    id: 'PACIFIC/KIRITIMATI'
  },
  {
    name: 'Pacific/Kosrae',
    offset: '39600',
    title: 'GMT (+11:00) Kosrae',
    id: 'PACIFIC/KOSRAE'
  },
  {
    name: 'Pacific/Kwajalein',
    offset: '43200',
    title: 'GMT (+12:00) Kwajalein',
    id: 'PACIFIC/KWAJALEIN'
  },
  {
    name: 'Pacific/Majuro',
    offset: '43200',
    title: 'GMT (+12:00) Majuro',
    id: 'PACIFIC/MAJURO'
  },
  {
    name: 'Pacific/Marquesas',
    offset: '-34200',
    title: 'GMT (-09:30) Marquesas',
    id: 'PACIFIC/MARQUESAS'
  },
  {
    name: 'Pacific/Midway',
    offset: '-39600',
    title: 'GMT (-11:00) Midway',
    id: 'PACIFIC/MIDWAY'
  },
  {
    name: 'Pacific/Nauru',
    offset: '43200',
    title: 'GMT (+12:00) Nauru',
    id: 'PACIFIC/NAURU'
  },
  {
    name: 'Pacific/Niue',
    offset: '-39600',
    title: 'GMT (-11:00) Niue',
    id: 'PACIFIC/NIUE'
  },
  {
    name: 'Pacific/Norfolk',
    offset: '39600',
    title: 'GMT (+11:00) Norfolk',
    id: 'PACIFIC/NORFOLK'
  },
  {
    name: 'Pacific/Noumea',
    offset: '39600',
    title: 'GMT (+11:00) Noumea',
    id: 'PACIFIC/NOUMEA'
  },
  {
    name: 'Pacific/Pago_Pago',
    offset: '-39600',
    title: 'GMT (-11:00) Pago Pago',
    id: 'PACIFIC/PAGO_PAGO'
  },
  {
    name: 'Pacific/Palau',
    offset: '32400',
    title: 'GMT (+09:00) Palau',
    id: 'PACIFIC/PALAU'
  },
  {
    name: 'Pacific/Pitcairn',
    offset: '-28800',
    title: 'GMT (-08:00) Pitcairn',
    id: 'PACIFIC/PITCAIRN'
  },
  {
    name: 'Pacific/Pohnpei',
    offset: '39600',
    title: 'GMT (+11:00) Pohnpei',
    id: 'PACIFIC/POHNPEI'
  },
  {
    name: 'Pacific/Port_Moresby',
    offset: '36000',
    title: 'GMT (+10:00) Port Moresby',
    id: 'PACIFIC/PORT_MORESBY'
  },
  {
    name: 'Pacific/Rarotonga',
    offset: '-36000',
    title: 'GMT (-10:00) Rarotonga',
    id: 'PACIFIC/RAROTONGA'
  },
  {
    name: 'Pacific/Saipan',
    offset: '36000',
    title: 'GMT (+10:00) Saipan',
    id: 'PACIFIC/SAIPAN'
  },
  {
    name: 'Pacific/Tahiti',
    offset: '-36000',
    title: 'GMT (-10:00) Tahiti',
    id: 'PACIFIC/TAHITI'
  },
  {
    name: 'Pacific/Tarawa',
    offset: '43200',
    title: 'GMT (+12:00) Tarawa',
    id: 'PACIFIC/TARAWA'
  },
  {
    name: 'Pacific/Tongatapu',
    offset: '46800',
    title: 'GMT (+13:00) Tongatapu',
    id: 'PACIFIC/TONGATAPU'
  },
  {
    name: 'Pacific/Wake',
    offset: '43200',
    title: 'GMT (+12:00) Wake',
    id: 'PACIFIC/WAKE'
  },
  {
    name: 'Pacific/Wallis',
    offset: '43200',
    title: 'GMT (+12:00) Wallis',
    id: 'PACIFIC/WALLIS'
  },
  {
    name: 'UTC',
    offset: '0',
    title: 'GMT (+00:00) UTC',
    id: 'UTC'
  }
]

export type Timezones = typeof TIMEZONES
export type Timezone = Timezones[number]
